.countdownArea {
    width: 760px;
    margin-left: auto;
    margin-right: auto;
}

.cd_auto_date {
    width: 760px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 24px;
    margin-top: 30px;
}

/* jQuery Countdown styles 1.6.1. */

.hasCountdown {
    /*	border: 1px solid #ccc;
        background-color: #eee;*/
    width: 100%;
    text-align: center;
    // padding-left: 160px;
    margin-top: 2rem;
}

.countdown_rtl {
    direction: rtl;
}

.countdown_holding span {
    color: #888;
}

.countdown_row {
    clear: both;
    width: 100%;
    padding: 20px 20px;
    text-align: center;
}

.countdown_show1 .countdown_section {
    text-align: center;
    width: 88%;
}

.countdown_show2 .countdown_section {
    text-align: center;
    width: 33%;
}

.countdown_show3 .countdown_section {
    text-align: center;
    width: 22%;
}

.countdown_show4 .countdown_section {
    text-align: center;
    width: 15%;
}

.countdown_show5 .countdown_section {
    text-align: center;
    width: 15%;
}

.countdown_show6 .countdown_section {
    text-align: center;
    width: 15%;
}

.countdown_show7 .countdown_section {
    text-align: center;
    width: 15%;
}

.countdown_section {
    padding: 10px;
    margin-right: 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    display: block;
    float: left;
    font-size: 14px;
    text-align: center;
    line-height: 24px;
}

.countdown_amount {
    font-size: 47px;
    line-height: 47px;
    font-weight: bold;
    padding-bottom: 15px;
    text-align: center;
}

.countdown_descr {
    display: block;
    width: 100%;
}

.countdown_holding span {
    color: #7b4e37;
}

.countdown_section {
    color: #7b4e37;
    background-color: #feda71;
    font-size: 11px;
    font-weight: normal;
    float: left;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    /*background-color: rgba(0,0,0,0.15);*/
    /*border: 2px solid rgba(0,0,0,0.10);*/
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.20);
    filter: dropshadow(color=rgba(0, 0, 0, 0.20), offx=2, offy=2);
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 15px;
    color: #FFF;
    border: 2px solid rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(linear, center top, center bottom, from(rgba(28, 28, 28, 0.05)), to(rgba(0, 0, 0, 0.05)));
    background-image: -webkit-linear-gradient(top, rgba(28, 28, 28, 0.05), rgba(0, 0, 0, 0.05));
    background-image: -moz-linear-gradient(top, rgba(28, 28, 28, 0.05), rgba(0, 0, 0, 0.05));
    background-image: -o-linear-gradient(top, rgba(28, 28, 28, 0.05), rgba(0, 0, 0, 0.05));
    background-image: -ms-linear-gradient(top, rgba(28, 28, 28, 0.05), rgba(0, 0, 0, 0.05));
    background-image: linear-gradient(to bottom, rgba(28, 28, 28, 0.05), rgba(0, 0, 0, 0.05));
    -webkit-box-shadow: 0 1px 5px rgba(20, 20, 20, 0.31), inset 0 0 1px 2px rgba(255, 255, 255, 0.16);
    -moz-box-shadow: 0 1px 5px rgba(20, 20, 20, 0.31), inset 0 0 1px 2px rgba(255, 255, 255, 0.16);
    box-shadow: 0 1px 5px rgba(20, 20, 20, 0.31), inset 0 0 1px 2px rgba(255, 255, 255, 0.16);
}

@media (max-width: 767px) {
    .hasCountdown{
        padding:0;
        width: 100%; 
        text-align: center;
    }
    .countdown_section{
        width: auto !important;
        display: inline-block !important;
        float: none !important;
    }

}