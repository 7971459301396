body{
  // background: url(https://cdn.endirecto.cl/background/bg1.jpg) !important;
  background-size: 100% !important;
  background-blend-mode: soft-light !important;
  // background: #009cde;
  }

h1{
  color: #fff;
}
